import { RiDownloadCloudLine } from "react-icons/ri";
import { Button as MaterialButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Col,
  List,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import { cards } from "./items";
import apis from "../../services/apis";
import { Card } from "../Exterior/Cards";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import { useAuth } from "../../contexts/AuthContext";
import { apiPayment } from "../../services/apiPayment";
import { useBroker } from "../../contexts/BrokerContext";
import { BackButton } from "../../components/BackButton";
import { ReportContainer, Container, Content } from "./styles";
import {
  download,
  errorMessage,
  getInitialYear,
  isMobile,
  monthsExtended,
  zero,
} from "../../utils";
import apiExterior from "../../services/apiExterior";
import { ModalConfirmMail } from "../../components/ModalConfirmMail";
import { UserPlanEnum } from "../../constants/plans";

interface ReportProps {
  item: Page;
}

export const ReportAlertTitle = (
  <Typography
    style={{
      fontSize: "14px",
      display: "flex",
      columnGap: "8px",
      lineHeight: "18px",
      alignItems: "flex-start",
    }}
  >
    <InfoCircleOutlined style={{ fontSize: "16px", marginTop: "2px" }} />{" "}
    <span>
      Os relatórios estão disponíveis somente no plano premium.{" "}
      <strong>Contrate o seu plano</strong>
    </span>
  </Typography>
);

export const ReportExterior: React.FC<ReportProps> = ({ item }) => {
  const navigate = useNavigate();
  const {
    showUserPlanModal,
    userPlanModal,
    ssoToken,
    hasPlan,
    hasPermissionExteriorPlanos,
    hasPermissionGeneral,
    user,
    updateUserInfo
  } = useAuth();
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const [initialYear, setInitialYear] = useState(currentYear);
  window.history.replaceState({}, document.title);

  const { currentPage } = useBroker();

  const [action, setAction] = useState<string>();

  const [data, setData] = useState<any[]>();
  const [infoModal, toggleModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(true);

  const [loadingLinks] = useState(false);
  const [year, setYear] = useState(initialYear);

  const [reportDisabled, setReportDisabled] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any>(null);

  const historicFeature = item.features[0];
  const downloadFeature = item.features[1];
  // const sendEmailFeature = item.features[2];
  const transactionsFeature = item.features[3];
  const darfFeature = item.features[4];

  const yearOptions = Array.from(Array(initialYear - 2020 + 1))
    .map((y, i) => ({
      value: 2020 + i,
      label: `${2020 + i}`,
    }))
    .reverse();

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  const getHistoric = useCallback(() => {
    if (!action) return;
    if (action === "anual-report")
      return setData(
        Array.from(Array(new Date().getFullYear() - 2022))
          .map((y, i) => ({
            year: 2022 + i,
            label: `${2022 + i}`,
          }))
          .reverse()
      );

    setLoading(true);
    (currentPage?.api || apis)
      .get(`${historicFeature.apiUrl}/${action || "gain"}`, {
        params: { year },
      })
      .then((response) => {
        const data = (response.data ?? []).reverse();
        setData(data);
        // getDowloadLinks(data, action || "gain");
      })
      .catch(() => message.error(errorMessage))
      .finally(() => setLoading(false));
  }, [historicFeature, year, currentPage, action /*, getDowloadLinks*/]);

  const sendReportMail = useCallback(() => {
    const reportType = reportData.type || 'anual-report';
    (currentPage?.api || apis).get(`/darf/report/mail/${reportType}`, {
      params: {
        year: reportData.year,
        month: reportData.month,
        filePath: reportData.filePath,
      }
    }).then(() => {
      message.success("Relatório enviado para seu email.")
    })
    .catch(() => {
      message.error("Houve um erro ao enviar seu relatório!")
    })
  }, [reportData])
  
  const downloadAction = (record: any): Promise<void> => {
    setLoading(true);
    const mobileAction = isMobile();

    return (currentPage?.api || apis)
      .post(`${downloadFeature.apiUrl}/${action || "anual-report"}`, {
        id: record._id,
        month: record.month,
        year: record.year,
        sendMail: record.sendMail || mobileAction,
      })
      .then((res) => {
        if (mobileAction) {
          message.success("Relatório enviado para seu email");
        } else if (res.data.fileUrl) {
          download(res.data.fileUrl);
          message.success("Relatório emitido com sucesso");
          if (res.data?.triggers?.plan) {
            message.success("Plano atualizado para Basic!");
          }
          if (action !== "income") {
            setReportData({
              filePath: res.data.filePath,
              month: record.month,
              year: record.year,
              type: action,
            });
          }
        }
        toggleModal(null);
      })
      .catch(({ response }) =>
        message.error(response?.data?.message ?? errorMessage)
      )
      .finally(() => setLoading(false));
  };

  const downloadDarf = async (data: any) => {
    setLoading(true);

    (currentPage?.api || apis).post(`${darfFeature.apiUrl}`, data)
      .then((res) => {
        if (!res?.data?.darfUrl ) {
          return message.error("Houve um erro ao baixar seu relatório!")
        }
        download(res.data.darfUrl);
        message.success("Relatório emitido com sucesso");
      })
      .catch(({ response }) =>
        message.error(response?.data?.message)
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePlanModal = () => {
    showUserPlanModal(!userPlanModal, ReportAlertTitle);
  };

  const checkDarf = (record: any) => {
    setLoading(true);
    (currentPage?.api || apis).get(`${transactionsFeature.apiUrl}`, { params: { year: record.year} })
    .then((res) => {
      const darf = res.data.find((d: any)=> {
        if (record?.month == d?.month) return d;
      })
      if (!darf || !darf?._id) {
        message.info("Cliente não possui relatório para este mês.");
        setLoading(false);
        return;
      }
      delete darf?.transactions;
      const data = {
        ...darf,
        year: record.year,
        month: record.month,
        name: user?.user?.name,
        memoriaCalculo: [],
        justSave: false,
        payed: false,
        noSendMail: true,
        paymentInfo: {
          clientInfo: {
            name: user?.user?.name,
            email: user?.user?.email,
            document: user?.user?.cpf,
          },
        },
      };
      setLoading(false);
      downloadDarf(data);
    })
    .catch(({ response }) => {
      const { data, status } = response;
      if (status === 401) message.error(data?.message);
      setLoading(false);
    });
  }

  const handleActionButton = (id: string) => {
    setAction(id);
    switch (id) {
      case "anual-report":
      case "income":
        setYear(2023);
        setInitialYear(2023);
        break;
      case "gain":
        setYear(2024);
        setInitialYear(2024);
        break;
    }
  };

  const handleActionButtonBack = (id: any) => {
    setAction(id);
  };

  const getErrors = () => {
    apiExterior
      .get(`/transaction/hasError`, { params: { notified: true } })
      .then((res: any) => {
        setReportDisabled((res?.data?.error?.errorDates || []).length > 0)
      })
      .catch(() => {
        console.log("Erro no error");
      })
      .finally(() => setLoadingError(false))
  }

  useEffect(() => {
    getErrors()
  }, [])

  useEffect(() => {
    getHistoric();
  }, [getHistoric, year]);

  return (
    <ReportContainer>
      <div className="content">
        <Typography.Title level={1}>Relatórios</Typography.Title>
        {action == "anual-report" &&
          <Typography.Paragraph>
            Confira os relatórios anuais de operações no
            exterior para a declaração do seu IR.
            A sua declaração de IRPF pré-preenchida com as informações da XP está
            disponível.
          </Typography.Paragraph>
        }

        {loadingError ? <LoadingOutlined /> : reportDisabled ?
          <div style={{width: '100%', display: 'flex', marginBottom: '1rem', padding: isMobile() ? '0 24px' : ''}}>
            <Typography.Text style={{fontSize: isMobile() ? '14px' : '16px'}}>
              Não foi possível carregar seu relatório. Verifique se você preencheu o custo médio de compra para os seus ativos de <Link to='/xpinvestimentos/transferencia-custodia'>Transferência de Custódia</Link>.
            </Typography.Text>
          </div>
          : !action && (
            <div className="cards">
              {cards.map((item) => (
                <Card
                  id={item.id}
                  key={item.id}
                  disabled={
                    loading ||
                    (hasPermissionExteriorPlanos && item.id !== "anual-report")
                  }
                  icon={loading ? <LoadingOutlined /> : item.icon}
                  onClick={() => {
                    if (!hasPlan && !hasPermissionGeneral) {
                     return navigate("/planos");
                    }

                    if (user?.user?.userPlanInfoVelotax?.type?.includes('BASIC') ||
                        user?.user?.userPlanInfoVelotax?.type === UserPlanEnum.XP_BASIC ||
                        user?.user?.userPlanInfoVelotax?.type === UserPlanEnum.VELOTAX_MAIN_BASIC) {
                      return navigate("/planos");
                    }

                    !loading && handleActionButton(item.id)
                  }}
                >
                  {item.id === "irpf" ? (
                    <>
                      <a
                        {...(!isMobile() ? { target: "_blank" } : {})}
                        href={
                          !process.env.REACT_APP_NODE_ENV?.includes("dev")
                            ? `https://irpf.velotax.com.br/p/sso/${ssoToken}`
                            : `https://irpfdev.velotax.com.br/p/sso/${ssoToken}`
                        }
                      >
                        {" "}
                      </a>
                      {item.content}
                    </>
                  ) : (
                    <>
                      {item.content}
                      {item.description && (
                        <div className="description">{item.description}</div>
                      )}
                    </>
                  )}
                </Card>
              ))}
            </div>
          )}
        {!action && (
          <BackButton
            to="/xpinvestimentos/exterior"
            className="report-exterior"
          />
        )}
        {action && (
          <Container>
            <Content>
              <Typography.Paragraph>Selecione o ano:</Typography.Paragraph>
              {action !== "anual-report" && (
                <Select
                  size="large"
                  options={yearOptions}
                  value={year.toString()}
                  onChange={onChangeYear}
                  placeholder="Selecione o ano"
                  style={{ minWidth: "150px" }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              )}
              <List
                loading={loading}
                dataSource={
                  data?.filter(
                    (item) =>
                      action === "anual-report" ||
                      item.year === year ||
                      item.year === currentYear
                  ) ?? []
                }
                itemLayout="horizontal"
                renderItem={(item, index) => {
                  // if(item.year === 2024 && action === "anual-report") return <></>
                  return  <List.Item
                  actions={[
                    <Button
                      type="text"
                      size="large"
                      href={item.url}
                      disabled={loadingLinks}
                      onClick={
                        () =>
                        {
                          (action === "anual-report")
                            ? toggleModal(item)
                            : ((action === "gain") && year === 2024)
                              ? checkDarf(item)
                              : downloadAction(item)
                        }
                      }
                      download={`Relatório-${item.year}-${zero(
                        item.month
                      )}.pdf`}
                    >
                      Baixar
                      {loadingLinks ? (
                        <LoadingOutlined size={16} />
                      ) : (
                        <RiDownloadCloudLine size={16} />
                      )}
                    </Button>,
                  ]}
                >
                  <Skeleton title={true} loading={item.loading} active>
                    <List.Item.Meta
                      title={
                        action !== "anual-report"
                          ? `${monthsExtended[item.month - 1]}`
                          : `${item.year}`
                      }
                    />
                  </Skeleton>
                </List.Item>
                }}
              />
              <Button
                type="text"
                size="large"
                onClick={() => handleActionButtonBack("")}
                style={{ marginTop: "0.5rem" }}
              >
                Voltar
              </Button>
            </Content>
          </Container>
        )}
        <Modal
          title="Aviso"
          maskClosable={true}
          visible={!!infoModal}
          onCancel={() => {
            toggleModal(null);
          }}
          footer={
            <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
              <Col span={24}>
                <MaterialButton
                  fullWidth
                  size="large"
                  color="secondary"
                  disabled={loading}
                  variant="contained"
                  onClick={() => {
                    downloadAction(infoModal);
                  }}
                >
                  {isMobile() ? 'Enviar informe por email' : 'Continuar'}
                </MaterialButton>
              </Col>
              <Col xs={24} sm={12}>
                <MaterialButton
                  fullWidth
                  size="small"
                  variant="text"
                  color="secondary"
                  disabled={loading}
                  onClick={() => {
                    navigate("/xpinvestimentos/exterior-historic");
                  }}
                >
                  Ir para calculadora de DARF
                </MaterialButton>
              </Col>
            </Row>
          }
        >
          <Typography.Paragraph style={{ marginTop: "-24px" }}>
            <strong>
              Caso você tenha realizado operações de venda de ativos ou recebido
              cupom de bonds em {year},
            </strong>{" "}
            certifique-se de que todos os meses estejam com a situação "Regular"
            na Calculadora de DARF.
          </Typography.Paragraph>
        </Modal>
        <ModalConfirmMail
          closeFn={() => {setReportData(null); updateUserInfo()}}
          visible={Boolean(reportData)}
          action={(sendMail) => {
            if (sendMail) {sendReportMail(); updateUserInfo()};
          }}
        />
      </div>
    </ReportContainer>
  );
};

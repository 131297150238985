import clsx from "clsx";
import moment from "moment-timezone";
import { BsExclamationCircle, BsEye } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { Button as MuiButton } from "@mui/material";
import { AiOutlineCalendar, AiOutlineLock } from "react-icons/ai";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Col,
  List,
  message,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { PayButton } from "./PayButton";
import { DarfBolsa } from "../DarfBolsa";
import Button from "../../components/Button";
import { AttentionIconContainer, BottomAdvice, Container, Content } from "./styles";
import { Page } from "../../constants/brokers";
import apiBolsa from "../../services/apiBolsa";
import { PayDarfButton } from "./PayDarfButton";
import HandleTag from "../../services/handleTag";
import { itensBolsa } from "../Bolsa/itensBolsa";
import { CardXP } from "../../components/CardXP";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { BackButton } from "../../components/BackButton";
import { DrawerModal } from "../../components/DrawerModal";
import UploadVelotax from "../../components/UploadVelotax";
import { PaymentModal } from "../../components/PaymentModal";
import { useB3Integration } from "../../contexts/B3IntegrationContext";
import { maxCreditCardPayment, maxPixPayment } from "../../constants/darf";
import { useOldTransactions } from "../../contexts/OldTransactionsContext";
import { useNotasCorretagem } from "../IntegrationBolsa/NotasCorretagem/useNotasCorretagem";
import {
  impostoCharge,
  showPayButton,
  valueIsGreaterThanMin,
  vendasIsentas,
  vendasTotais,
} from "./utils";
import {
  download,
  errorMessage,
  formatCurrency,
  getInitialYear,
  getLastBusinessDay,
  getZeroBuyPrice,
  isMobile,
  isSecondBusinessDay,
  monthsExtended,
} from "../../utils";
import { NotasHistoricoModal } from "../IntegrationBolsa/NotasHistoricoModal";
import { instanceMsCalc } from "../../services/msCalc";
import { ModalZeroBuyPrice } from "../../components/ModalZeroBuyPrice";

interface ShowCalcModal {
  visible: boolean;
  view?: boolean;
  month?: number;
  year?: number;
}

interface HistoricProps {
  item: Page;
}

export const HistoricBolsa: React.FC<HistoricProps> = ({ item }) => {
  const { state } = useLocation();
  const navigate = useNavigate()
  const darfBolsaRef = useRef<any>();
  const { oldPositionList } = useOldTransactions();
  const {
    user,
    hasPlan: hasPremiumPlan,
    hasPermissionGeneral,
    showUserPlanModal,
  } = useAuth();
  const hasPlan = hasPremiumPlan || hasPermissionGeneral;
  const { integration, initIntegration, integrouHoje } = useBroker();
  const {
    b3Authorized,
    handleIntegrate,
    interval,
    backgroundIntegration,
    notas: {
      getHistoricoNotasCorretagem,
      setShowHistoricoNotasCorretagemModal,
    },
  } = useB3Integration();
  const {
    sendNotas,
    loadingSend,
    uploadProps,
    showNotasCorretagemModal,
    setShowNotasCorretagemModal,
  } = useNotasCorretagem();

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const stateYear = (state as any)?.year;
  const initialYear = getInitialYear(currentYear, currentMonth);
  window.history.replaceState({}, document.title);

  const [data, setData] = useState<any[]>();
  const [holidays, setHolidays] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [emitting, setEmitting] = useState(false);
  const [itemToPay, setItemToPay] = useState<any>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [year, setYear] = useState(stateYear ?? initialYear);
  const [showCalcModal, setShowCalcModal] = useState<ShowCalcModal>({
    visible: false,
  });
  const [ignoreZeroBuyPrice, setIgnoreZeroBuyPrice] = useState(false);
  const [dataZeroBuyPrice, setDataZeroBuyPrice] = useState<any[]>([]);
  const [modalZeroBuyPrice, setModalZeroBuyPrice] = useState(false);

  const isBasic = user.user?.userPlanInfoVelotax?.type?.includes('BASIC');

  const historicFeature = item.features[0];
  const payFeature = item.features[1];

  const yearOptions = Array.from(Array(initialYear - 2020 + 1))
    .map((y, i) => ({
      value: 2020 + i,
      label: `${2020 + i}`,
    }))
    .reverse();

  const getHistoric = useCallback(() => {
    setLoading(true);
    apiBolsa
      .get(user?.user?.cpf === '01268936324' ? '/xpinvestimentos/historic2' : historicFeature.apiUrl, { params: { year } })
      .then((response) => {
        const data = (response.data ?? []).reverse();
        setData(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [historicFeature, year]);

  const pay = useCallback(
    (payed: boolean, item: any) => {
      setLoading(true);
      apiBolsa
        .put(`${payFeature.apiUrl}/${item._id}`, { payed })
        .then(() => {
          getHistoric();
          setData((data) =>
            (data || []).map((darf) => ({
              ...darf,
              payed: item._id === darf._id ? payed : darf.payed,
            }))
          );
        })
        .catch(() => message.error(errorMessage))
        .finally(() => setLoading(false));
    },
    [payFeature, getHistoric]
  );

  const handleOpenPlanModal = () => {
    navigate('/planos')
  };

  const handleOpenNotas = () => {
    setShowNotasCorretagemModal(true);
  };

  const editAction = (
    record: any,
    view: boolean,
    index: number,
    noPlanMode?: boolean
  ) => {
    if (!hasPlan && !noPlanMode) {
      handleOpenPlanModal();
    } else {
      const { month, year } = record;
      setShowCalcModal({ visible: true, view, month, year });
      if (view) {
        setItemToPay(record);
      }
    }
  };

  const payAction = (value: boolean, record: any) => {
    pay(value, record);
  };

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  // const sendDarfToEmail = (darfId: string) => {
  //   apiBolsa
  //     .get(`/darf/sendEmail/${darfId}`)
  //     .then(() => {
  //       message.success("O DARF foi enviado para o seu e-mail");
  //     })
  //     .catch((error) => {
  //       console.log("Algo inesperado aconteceu. Tente novamente!");
  //     });
  // };

  useEffect(() => {
    if (ignoreZeroBuyPrice) handlePayButton(item);
  }, [ignoreZeroBuyPrice])

  const handlePayButton = async (item: any) => {
    if (!hasPlan || isBasic) {
      handleOpenPlanModal();
    } else {
      if (!ignoreZeroBuyPrice) {
        const hasZeroBuyPrice = await getZeroBuyPrice(item?.memoriaCalculo);
        if (hasZeroBuyPrice.length > 0) {
          setModalZeroBuyPrice(true);
          setDataZeroBuyPrice(hasZeroBuyPrice);
          getTaxes(item.month, false, false, false, true, false);

          return;
        }
      }
      getTaxes(item.month, false, false, false, true, false);
      setPaymentModal(!paymentModal);
    }
  };

  const handleDarf = (justSave?: boolean, obj?: any) => {
    setEmitting(true);
    const data = obj ? obj : itemToPay;
    apiBolsa
      .post("/darf", {
        ...data,
        memoriaCalculo: [],
        transactions: data?.memoriaCalculo,
        totalImpostoDevido: data?.impostoTotalFinal,
        impostoDevido: data?.impostoTotal,
        juros: data?.jurosAux,
        multa: data?.multaAux,
        year,
        month: data?.month,
        name: user.user.name,
        insertPix: true,
        paymentInfo: {
          clientInfo: {
            name: user?.user?.name,
            email: user?.user?.email,
            document: user?.user?.cpf,
          },
        },
        justSave,
      })
      .then((res) => {
        getHistoric();
        setEmitting(false);
        if (!justSave) {
          setPaymentModal((paymentModal) => !paymentModal);
          download(res.data.darfUrl);
          message.success("O DARF foi enviado para o seu e-mail");
        }
      })
      .catch((err) => {
        message.error(err.response?.data?.message || errorMessage);
        setEmitting(false);
      });
  };

  const getTaxes = useCallback(
    (
      month: number,
      nAtualizarDarfsPassadas?: boolean,
      nAtualizarIrrf?: boolean,
      atualizouImpostoAcumulado?: boolean,
      nAtualizarPrejuizosAcumulados?: boolean,
      atualizarTotalmente?: boolean,
      atualizouCorretagem?: boolean,
      callback?: (obj: any) => void
    ) => {
      apiBolsa
        .get("/xpinvestimentos/transaction", {
          params: {
            year,
            month,
            nAtualizarDarfsPassadas: nAtualizarDarfsPassadas || false,
            nAtualizarIrrf: nAtualizarIrrf || false,
            atualizouImpostoAcumulado: atualizouImpostoAcumulado || false,
            nAtualizarPrejuizosAcumulados:
              nAtualizarPrejuizosAcumulados || false,
            atualizarTotalmente: atualizarTotalmente || false,
            atualizouCorretagem: atualizouCorretagem || false,
          },
        })
        .then((response) => {
          if (response.data.memoriaCalculo) {
            const data = {
              ...response.data,
              year,
              month,
              multa: response.data?.multaAux || response.data?.multa,
              juros: response.data?.jurosAux || response.data?.juros,
              impostoDevido: response.data?.impostoTotal || response.data?.impostoDevido,
              memoriaCalculo: response.data.memoriaCalculo.map(
                (memoCalc: any) => ({
                  ...memoCalc,
                  date: moment(memoCalc.date.slice(0, 10)).format("DD/MM/YYYY"),
                  originalDate: memoCalc.date,
                  market:
                    memoCalc.market === "Opção de Compra" ||
                      memoCalc.market === "Opção de Venda"
                      ? "Mercado de opções"
                      : memoCalc.market,
                })
              ),
            };
            setItemToPay(data);
            callback?.(data);
          }
        })
        .catch(() => { });
    },
    [year]
  );

  const handleCloseCalcModal = () => {
    setShowCalcModal({ visible: false });
    getHistoric();
  };

  const getHolidays = async () => {
    instanceMsCalc.get(`/rates/get-holidays`)
      .then(({ data }) => {
        const dataFormated = data?.map((el: any) => {
          return moment(el).tz('Europe/London').format('MM-DD');
        });
        setHolidays(dataFormated);
      })
      .catch(() => console.error('Erro ao buscar feriados'));
  }

  useEffect(() => {
    getHistoric();
    getHolidays();
  }, [getHistoric, year, initIntegration]);

  useEffect(() => {
    if (
      user.user &&
      b3Authorized &&
      !integrouHoje &&
      !interval.current &&
      integration.key !== "unset"
    ) {
      handleIntegrate({ integrationInBackground: true });
    }
  }, [
    interval,
    user.user,
    integrouHoje,
    b3Authorized,
    handleIntegrate,
    integration.key,
  ]);

  const isAntesTerceiroDiaUtil = () => {
    const today = moment();

    // Verifica se a data de hoje é um sábado
    if (today.isoWeekday() === 6) {
      return false;
    }

    // Verifica se a data de hoje é anterior ao terceiro dia útil
    const firstDayOfMonth = moment(today).startOf('month');
    let businessDaysCount = 0;

    while (businessDaysCount < 2) {
      if (firstDayOfMonth.isoWeekday() < 6) {
        businessDaysCount++;
      }

      firstDayOfMonth.add(1, 'day');
    }

    return today.isBefore(firstDayOfMonth);
  }

  const thirdBusinessDayAndMonth = (item: any) => {
    if (disableCurrentMonth(item))
      return { disabled: true, title: "Aguarde o encerramento do mês para pagar seu DARF" }
    else if (item.month == currentMonth && item.year == currentYear && isAntesTerceiroDiaUtil())
      return { disabled: true, title: "Aguarde o terceiro dia útil deste mês para pagar seu DARF" }
    else return { disabled: false, title: "" }
  }

  const backgroundIntegrationDisabled = (item: any) =>
    backgroundIntegration.loading &&
    (item.year > backgroundIntegration.year ||
      (item.year === backgroundIntegration.year &&
        item.month >= backgroundIntegration.month));

  const disableCurrentMonth = (item: any) =>
    item.year === currentYear && item.month === currentMonth + 1;

  return (
    <Container className="velotax-container">
      <Typography.Title className="page-title" level={1}>
        Impostos devidos (DARF)
      </Typography.Title>
      <Content className="velotax-content historic">
        <Select
          size="large"
          options={yearOptions}
          value={year.toString()}
          onChange={onChangeYear}
          className="year-select"
          placeholder="Selecione o ano"
          style={{ minWidth: "150px" }}
          suffixIcon={<AiOutlineCalendar size={24} />}
          getPopupContainer={(trigger) => trigger.parentElement}
        />
        <List
          loading={loading}
          itemLayout="horizontal"
          className={clsx("velotax-historic-list", { "no-plan": !hasPlan })}
          dataSource={
            data?.filter(
              (item) =>
                !(item.year === currentYear && item.month > currentMonth + 1)
            ) ?? []
          }
          renderItem={(item, index) => {
            const ultimoDiaUtilDoMes = getLastBusinessDay(
              new Date(item.year, item.month + 1, 0),
              holidays,
            );
            const vencimento = moment(ultimoDiaUtilDoMes).format("DD/MM/YYYY");
            const currentDarfDate = new Date(year, item.month -1, 15);
            let dateAllowedOnBasic = new Date(today.getFullYear(), today.getMonth() - 1, 1)
            if(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt) {
              dateAllowedOnBasic = new Date(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt);
              dateAllowedOnBasic.setMonth(dateAllowedOnBasic.getMonth() - 2)
            }
            const checkMonth = (currentDarfDate.getTime() < dateAllowedOnBasic.getTime());
            const hideOnBasic = isBasic && checkMonth;
            const disabled = backgroundIntegrationDisabled(item);
            return (
              <List.Item
                actions={
                 (!hasPlan || hideOnBasic)
                    ? item.transactions?.length > 0 && !isMobile()
                      ? [
                        <Button
                          type="link"
                          icon={<BsEye size={16} />}
                          disabled={disabled}
                          onClick={() => editAction(item, true, index, true)}
                        >
                          <strong>Visualizar</strong>
                        </Button>,
                      ]
                      : []
                    : !isMobile()
                      ? [
                        showPayButton(item) && (
                          <PayButton
                            onClick={() => handlePayButton(item)}
                            title={
                              thirdBusinessDayAndMonth(item).title
                            }
                            disabled={
                              disabled || thirdBusinessDayAndMonth(item).disabled
                            }
                          />
                        ),
                        <Button
                          type="link"
                          icon={<BsEye size={16} />}
                          disabled={disabled}
                          onClick={() =>
                            editAction(
                              item,
                              item.payed && valueIsGreaterThanMin(item),
                              index
                            )
                          }
                        >
                          {showPayButton(item) ? (
                            ""
                          ) : (
                            <strong>Visualizar</strong>
                          )}
                        </Button>,
                      ]
                      : []
                }
              >
                <Skeleton title={false} loading={item.loading} active>
                  <List.Item.Meta
                    title={
                      <>
                        {monthsExtended[item.month - 1]}
                        {!isMobile() ? ` de ${item.year}` : ""}
                        {(!hasPlan || hideOnBasic) &&
                          item.transactions?.length > 0 &&
                          !disabled &&
                          isMobile() && (
                            <Button
                              type="link"
                              onClick={() =>
                                editAction(item, true, index, true)
                              }
                              icon={
                                <BsEye
                                  size={16}
                                  style={{
                                    minWidth: "24px",
                                    minHeight: "24px",
                                    marginLeft: "12px",
                                    strokeWidth: "0.2px",
                                    fill: "var(--ant-primary-color)",
                                  }}
                                />
                              }
                            />
                          )}
                        {(hasPlan && !hideOnBasic) ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {valueIsGreaterThanMin(item) &&
                              !disableCurrentMonth(item) &&
                              !disabled ? (
                              <PayDarfButton
                                checked={item.payed}
                                checkedChildren="Pago"
                                unCheckedChildren="Em aberto"
                                onChange={() => {
                                  if (item._id) {
                                    payAction(!item.payed, item);
                                  } else {
                                    getTaxes(
                                      item.month,
                                      false,
                                      false,
                                      false,
                                      true,
                                      false,
                                      false,
                                      (obj: any) => {
                                        handleDarf(true, obj);
                                      }
                                    );
                                  }
                                }}
                              />
                            ) : undefined}
                            {isMobile() &&
                              !disabled && (
                                <Button
                                  type="link"
                                  icon={
                                    <BsEye
                                      size={16}
                                      style={{
                                        minWidth: "24px",
                                        minHeight: "24px",
                                        marginLeft: "12px",
                                        strokeWidth: "0.2px",
                                        fill: "var(--ant-primary-color)",
                                      }}
                                    />
                                  }
                                  onClick={() =>
                                    editAction(
                                      item,
                                      item.payed && valueIsGreaterThanMin(item),
                                      index
                                    )
                                  }
                                />
                              )}
                          </div>
                        ) : undefined}
                      </>
                    }
                    description={
                      <Space direction="vertical">
                        {(!hasPlan || hideOnBasic) ? (
                          <>
                            {(item.transactions?.length > 0 || disabled) ? (
                              <>
                                <Typography.Text>
                                  <span>Vendas totais: </span>
                                  <strong>
                                    {disabled ? (
                                      <>
                                        <Typography.Text
                                          style={{
                                            fontSize: "14px",
                                            marginRight: "12px",
                                          }}
                                        >
                                          Atualizando...
                                        </Typography.Text>
                                        <LoadingOutlined />
                                      </>
                                    ) : (
                                      formatCurrency(vendasTotais(item))
                                    )}
                                  </strong>
                                </Typography.Text>
                                <Typography.Text className="no-plan-imposto-a-pagar">
                                  <span>Imposto a pagar: </span>
                                  <Button
                                    type="link"
                                    disabled={backgroundIntegrationDisabled(
                                      item
                                    )}
                                    onClick={() => {
                                      navigate('/planos')
                                    }}
                                  >
                                    <strong>
                                      Clique para verificar&nbsp;
                                      <AiOutlineLock size={14} />
                                    </strong>
                                  </Button>
                                </Typography.Text>
                              </>
                            ) : (
                              <Typography.Text style={{ width: "100%" }}>
                              {
                                user?.user?.cpf === '01268936324' 
                                ? 'Clique em visualizar para acessar sua operações'
                                : 'Não houve movimentações tributáveis neste mês'
                              }
                              </Typography.Text>
                            )}
                          </>
                        ) : (
                          <>
                            {((item.transactions?.length > 0) &&
                              (impostoCharge(item) > 0
                                || vendasTotais(item) > 0)) ? (
                              <>
                                <Typography.Text>
                                  <span>Vendas totais: </span>
                                  <strong>
                                    {disabled ? (
                                      <>
                                        <Typography.Text
                                          style={{
                                            fontSize: "14px",
                                            marginRight: "12px",
                                          }}
                                        >
                                          Atualizando...
                                        </Typography.Text>
                                        <LoadingOutlined />
                                      </>
                                    ) : (
                                      formatCurrency(vendasTotais(item))
                                    )}
                                  </strong>
                                </Typography.Text>
                                {disableCurrentMonth(item) && (
                                  <Typography.Text>
                                    <span>Vendas isentas: </span>
                                    <strong>
                                      {disabled ? (
                                        <>
                                          <Typography.Text
                                            style={{
                                              fontSize: "14px",
                                              marginRight: "12px",
                                            }}
                                          >
                                            Atualizando...
                                          </Typography.Text>
                                          <LoadingOutlined />
                                        </>
                                      ) : (
                                        <>
                                          {formatCurrency(vendasIsentas(item))}
                                          &nbsp;
                                          <Tooltip
                                            title="Vendas mensais de até R$ 20 mil no mercado comum são isentas de imposto de renda"
                                            autoAdjustOverflow={true}
                                            placement="topRight"
                                          >
                                            <InfoCircleOutlined />
                                          </Tooltip>
                                        </>
                                      )}
                                    </strong>
                                  </Typography.Text>
                                )}
                                <Typography.Text>
                                  <span>Imposto a pagar: </span>
                                  <span className="tax-value">
                                    {disabled ? (
                                      <>
                                        <Typography.Text
                                          style={{
                                            fontSize: "14px",
                                            marginRight: "12px",
                                            wordSpacing: "normal",
                                            letterSpacing: "normal",
                                          }}
                                        >
                                          Atualizando...
                                        </Typography.Text>
                                        <LoadingOutlined />
                                      </>
                                    ) : impostoCharge(item) > 0 ? (
                                      formatCurrency(impostoCharge(item))
                                    ) : (
                                      "Isento"
                                    )}
                                  </span>
                                </Typography.Text>
                                {impostoCharge(item) > 0 && (
                                  <Typography.Text>
                                    <span>Vencimento: </span>
                                    <span>
                                      {disabled ? (
                                        <>
                                          <Typography.Text
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "700",
                                              marginRight: "12px",
                                            }}
                                          >
                                            Atualizando...
                                          </Typography.Text>
                                          <LoadingOutlined />
                                        </>
                                      ) : (
                                        vencimento
                                      )}
                                    </span>
                                  </Typography.Text>
                                )}
                                {isMobile() && showPayButton(item) && (
                                  <PayButton
                                    fullWidth
                                    onClick={() => handlePayButton(item)}
                                    title={
                                      thirdBusinessDayAndMonth(item).title
                                    }
                                    disabled={
                                      disabled || thirdBusinessDayAndMonth(item).disabled
                                    }
                                  />
                                )}
                              </>
                            ) : (
                              <Typography.Text>
                                {disabled ? (
                                  <>
                                    <Col style={{ width: "100%" }}>
                                      <Typography.Text>
                                        <span>Vendas totais: </span>
                                        <strong>
                                          <Typography.Text
                                            style={{
                                              fontSize: "14px",
                                              marginRight: "12px",
                                            }}
                                          >
                                            Atualizando...
                                          </Typography.Text>
                                          <LoadingOutlined />
                                        </strong>
                                      </Typography.Text>
                                    </Col>
                                    <Col style={{ width: "100%" }}>
                                      <Typography.Text>
                                        <span>Imposto a pagar: </span>
                                        <span className="tax-value">
                                          <Typography.Text
                                            style={{
                                              fontSize: "14px",
                                              marginRight: "12px",
                                              wordSpacing: "normal",
                                              letterSpacing: "normal",
                                            }}
                                          >
                                            Atualizando...
                                          </Typography.Text>
                                          <LoadingOutlined />
                                        </span>
                                      </Typography.Text>
                                    </Col>
                                  </>
                                ) : (
                                  <Typography.Text style={{ width: "100%" }}>
                                    {
                                      user?.user?.cpf === '01268936324' 
                                      ? 'Clique em visualizar para acessar sua operações'
                                      : 'Não houve movimentações tributáveis neste mês'
                                    }
                                  </Typography.Text>
                                )}
                              </Typography.Text>
                            )}
                          </>
                        )}
                      </Space>
                    }
                  />
                </Skeleton>
              </List.Item>
            );
          }}
        />
        <Typography.Title level={2} className="subtitle-lined">
          <span>Outras opções</span>
        </Typography.Title>

        <div>
          {[...itensBolsa.slice(1)]
            .filter(
              (item) =>
              !(["insert-manual", "anual-report"].includes(item.id)) || oldPositionList?.length > 0
            )
            .map((item) => (
              <>
                <CardXP
                  id={item.id}
                  key={item.id}
                  icon={item.icon}
                  link={item.link}
                  className={clsx("xp-list-card", {
                    "no-plan": !hasPlan,
                    "not-free": !hasPlan && item.id !== "integration",
                  })}
                  onClick={
                    item.id === "insert-manual" ?
                      undefined
                      : item.id === "integration"
                        ? () => handleOpenNotas()
                        : ((hasPlan && !isBasic))
                          ? undefined
                          : () => {
                            handleOpenPlanModal();
                          }
                  }
                >
                  {((hasPlan && !isBasic) || item.id === "integration" || item.id === 'insert-manual') ? (
                    <>{item.content}</>
                  ) : (
                    <Row align="middle" justify="space-between" gutter={[8, 8]}>
                      <Col>{item.content}</Col>
                      <Button
                        type="primary"
                        className="btn-premium"
                        icon={<AiOutlineLock size={18} />}
                        onClick={() => {
                          handleOpenPlanModal();
                        }}
                      >
                        PREMIUM
                      </Button>
                    </Row>
                  )}
                </CardXP>
              </>
            ))}
        </div>
        <BackButton to="/xpinvestimentos/bolsa" className="historic-bolsa" />
      </Content>
      <BottomAdvice>
        <AttentionIconContainer>
        <BsExclamationCircle /> 
        </AttentionIconContainer>
        Informamos que, para pagamento do DARF via PIX ou cartão de crédito,
        é necessário que o mesmo seja realizado até as 17h30 para processamento no mesmo dia.
        Pagamentos efetuados após esse horário serão processados no próximo dia útil,
        o que pode resultar em alterações nos valores de juros e multa.
        <br />
        * Nos dias 24 e 30 de dezembro, é necessário que o pagamento seja realizado até as 12h 
        para processamento no mesmo dia.
      </BottomAdvice>

      <DrawerModal
        title=""
        width={700}
        className="darf-modal"
        visible={showCalcModal.visible}
        onCancel={() => {
          handleCloseCalcModal();
          if (darfBolsaRef?.current?.editedAccumulatedTax) {
            darfBolsaRef?.current?.handleDarf?.(true);
            darfBolsaRef?.current?.setEditedAccumulatedTax?.(false);
          }
        }}
        bodyStyle={{
          padding: "0",
        }}
      >
        <DarfBolsa
          view={showCalcModal.view}
          darfBolsaRef={darfBolsaRef}
          viewEdit={!showCalcModal.view}
          closeModal={handleCloseCalcModal}
          darf={
            hasPlan && showCalcModal.view
              ? {
                ...itemToPay,
                fullPath: itemToPay?.fullPath!,
                id: itemToPay.id ?? itemToPay._id,
              }
              : undefined
          }
          date={{
            year: showCalcModal.year,
            month: showCalcModal.month ? showCalcModal.month - 1 : 0,
          }}
          item={{
            title: "",
            header: true,
            sidebar: true,
            name: "Calculadora",
            component: "DarfBolsa",
            path: "/xpinvestimentos/bolsa-darf",
            settings: {
              integrationPath: "/xpinvestimentos/bolsa-integration",
              showNavigateToIntegrationButton: false,
            },
            features: [
              {
                apiUrl: "/xpinvestimentos/transaction",
              },
              {
                apiUrl: "/darf",
                buttonLabel: "Emitir DARF",
              },
              {
                apiUrl: "/darf/year",
              },
              {
                apiUrl: "/xpinvestimentos/prejuizos",
              },
              {
                apiUrl: "/xpinvestimentos/irrf",
              },
            ],
          }}
        />
      </DrawerModal>

      <PaymentModal
        fromDarf
        item={item}
        show={paymentModal}
        emitting={emitting}
        selectedYear={year}
        maxPixValue={maxPixPayment}
        maxCCValue={maxCreditCardPayment}
        closeDarfModal={handleCloseCalcModal}
        paymentData={{
          ...itemToPay,
          memoriaCalculo: [],
          name: user.user.name,
          year: itemToPay?.year,
          month: itemToPay?.month,
          transactions: itemToPay?.memoriaCalculo,
          impostoTotalFinal: impostoCharge(itemToPay),
        }}
        disablePixOption={(itemToPay?.impostoTotalFinal || 0) > maxPixPayment}
        disableCreditCardOption={
          (itemToPay?.impostoTotalFinal || 0) > maxCreditCardPayment
        }
        closeModal={() => {
          setPaymentModal(!paymentModal);
        }}
        onCancel={() => {
          setPaymentModal(!paymentModal);
          getHistoric();
        }}
        callDarf={() => {
          HandleTag("64");
          handleDarf();
        }}
      />
      <DrawerModal
        footer={null}
        visible={showNotasCorretagemModal}
        title="Carregar notas de corretagem"
        onCancel={() => {
          if (!loadingSend) {
            setShowNotasCorretagemModal(false);
          }
        }}
      >
        <Typography.Paragraph>
          <InfoCircleOutlined /> Você pode carregar as notas de corretagem para
          verificar o imposto de renda sobre operações realizadas no mercado de
          balcão
        </Typography.Paragraph>
        <UploadVelotax loading={loadingSend} {...uploadProps} />
        <Row justify="end">
          <Col>
            <MuiButton
              size="large"
              color="secondary"
              variant="contained"
              disabled={loadingSend}
              onClick={() => sendNotas()}
            >
              Finalizar
            </MuiButton>
          </Col>
        </Row>
        <Typography.Link
          style={{
            display: "block",
            margin: "32px auto",
            textAlign: "center",
            textDecoration: "underline",
          }}
          onClick={() => {
            setShowHistoricoNotasCorretagemModal(true);
            getHistoricoNotasCorretagem({ filters: {} });
          }}
        >
          Histórico de notas
        </Typography.Link>
      </DrawerModal>
      <ModalZeroBuyPrice
        visible={modalZeroBuyPrice}
        title={'Atenção'}
        dataZeroBuyPrice={dataZeroBuyPrice}
        setIgnoreZeroBuyPrice={setIgnoreZeroBuyPrice}
        setModalZeroBuyPrice={setModalZeroBuyPrice}
      >
      </ModalZeroBuyPrice>
      <NotasHistoricoModal />
    </Container>
  );
};

import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AiOutlineLock } from "react-icons/ai";
import { LoadingOutlined } from "@ant-design/icons";
import { Col, Modal, Row, Typography, message } from "antd";
import { Card } from "./Cards";
import apis from "../../services/apis";
import { HomeContainer } from "../Home/styles";
import AntButton from "../../components/Button";
import { itensExterior } from "./itensExterior";
import { useAuth } from "../../contexts/AuthContext";
import { download, errorMessage } from "../../utils";
import { useBroker } from "../../contexts/BrokerContext";
import apiExterior from "../../services/apiExterior";
import { ModalIntegrationExterior } from "./ModalIntegrationExterior";
import { UserPlanEnum } from "../../constants/plans";

export const Exterior = () => {
  const navigate = useNavigate();
  const { currentPage } = useBroker();
  const { showUserPlanModal, hasPlan, hasPermissionGeneral, user } = useAuth();
  // const [loading, setLoading] = useState(false);
  const [infoModal, toggleModal] = useState(null);
  const [reportLoading, setReportLoading] = useState(false);
  const [, setReportDisabled] = useState<boolean>(false);
  const [showNoOperationsModal, setShowNoOperationsModal] = useState(false);

  const checkOperations = async () => {
    try {
      const { data } = await (currentPage?.api || apis).get(
        "/xpinvestimentos/has-operations"
      );
      return data?.has;
    } catch {
      return false;
    }
  };

  const handleReportClick = async () => {
    const hasOperations = await checkOperations();
    if (hasOperations) {
      navigate("/xpinvestimentos/exterior-report");
    } else {
      setShowNoOperationsModal(true);
    }
  };

  const downloadAction = (record: any): Promise<void> => {
    setReportLoading(true);
    return (currentPage?.api || apis)
      .post("/darf/report/download/anual-report", {
        id: record._id,
        month: record.month,
        year: record.year,
      })
      .then((res) => {
        if (res.data.fileUrl) {
          download(res.data.fileUrl);
        }
        if (res.data.file) {
          // downloadPDF(res.data.file);
          message.success("Relatório enviado para o seu e-mail");
        }
      })
      .catch(({ response }) =>
        message.error(response?.data?.message ?? errorMessage)
      )
      .finally(() => {
        toggleModal(null);
        setReportLoading(false);
      });
  };

  const handlePlanModal = () => {
    // showUserPlanModal(!userPlanModal, ReportAlertTitle);
    navigate("/planos");
  };

  const handleOpenPlanModal = () => {
    showUserPlanModal(true);
  };

  // const handleAnualReport = async (id: string) => {
  //   let retPlanInfo = {
  //     data: {
  //       active: false,
  //       permissions: {
  //         "exterior-xpinvestimentos": false,
  //         "relatoriosanual-xpinvestimentos": false,
  //       },
  //     },
  //   };

  //   setLoading(true);
  //   try {
  //     retPlanInfo = await apiPayment.get("/user-plan/plan-info");
  //   } catch (err) {
  //     console.error(err);
  //   }

  //   const hasntPlan = !retPlanInfo.data.active;
  //   const hasExteriorCalcActive =
  //     retPlanInfo.data.permissions["exterior-xpinvestimentos"];
  //   const hasYearlyReportsCalcActive =
  //     retPlanInfo.data.permissions["relatoriosanual-xpinvestimentos"];

  //   setLoading(false);

  //   let blockHandle = hasntPlan && !hasExteriorCalcActive;

  //   if (id === "anual-report") {
  //     blockHandle =
  //       hasntPlan && !hasExteriorCalcActive && !hasYearlyReportsCalcActive;
  //   }

  //   if (blockHandle) {
  //     handlePlanModal();
  //   } else {
  //     toggleModal({ year: 2022 } as any);
  //   }
  // };

  const getErrors = () => {
    apiExterior
      .get(`/transaction/hasError`, { params: { notified: true } })
      .then((res: any) => {
        setReportDisabled((res?.data?.error?.errorDates || []).length > 0);
      })
      .catch(() => {
        console.log("Erro no error");
      });
  };

  useEffect(() => {
    getErrors();
  }, []);

  return (
    <HomeContainer>
      <div className="content exterior">
        <div>
          <Typography.Title level={1}>Ativos no exterior</Typography.Title>
          <Typography.Text className="destak">
            Selecione a opção que deseja utilizar
          </Typography.Text>
          <div className="cards cards-2">
            {itensExterior.slice(0, 4).map((item) => (
              <Card
                id={item.id}
                key={item.id}
                link={item.link}
                icon={item.icon}
                onClick={
                  item.id === "report"
                    ? () => {
                       if (!hasPlan && !hasPermissionGeneral) {
                          return navigate("/planos");
                        }
    
                        if (user?.user?.userPlanInfoVelotax?.type?.includes('BASIC') ||
                            user?.user?.userPlanInfoVelotax?.type === UserPlanEnum.XP_BASIC ||
                            user?.user?.userPlanInfoVelotax?.type === UserPlanEnum.VELOTAX_MAIN_BASIC) {
                          return navigate("/planos");
                        }
                      handleReportClick()
                    }
                    : item.showToBasic && hasPlan && hasPlan.basic
                    ? undefined
                    : item.premium && !hasPlan.premium && !hasPlan.concierge
                    ? () => {
                        handleOpenPlanModal();
                      }
                    : undefined
                }
              >
                {item.content}
                {item.description && (
                  <div className="description">{item.description}</div>
                )}
                {item.showToBasic &&
                hasPlan &&
                hasPlan.basic ? null : item.premium &&
                  !hasPlan.premium &&
                  !hasPlan.concierge ? (
                  <AntButton
                    type="primary"
                    className="btn-premium"
                    icon={<AiOutlineLock size={18} />}
                    onClick={() => {
                      handlePlanModal();
                    }}
                  >
                    PREMIUM
                  </AntButton>
                ) : null}
              </Card>
            ))}
          </div>
        </div>
      </div>
      <Modal
        title="Aviso"
        visible={!!infoModal}
        closable={!reportLoading}
        maskClosable={!reportLoading}
        onCancel={() => {
          if (!reportLoading) {
            toggleModal(null);
          }
        }}
        footer={
          <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
            <Col span={24}>
              <Button
                fullWidth
                size="large"
                color="secondary"
                variant="contained"
                onClick={() => {
                  if (!reportLoading) {
                    downloadAction(infoModal);
                  }
                }}
              >
                {reportLoading ? (
                  <>
                    <LoadingOutlined />
                    &nbsp;Gerando relatório
                  </>
                ) : (
                  "Continuar"
                )}
              </Button>
            </Col>
            <Col xs={24} sm={12}>
              <Button
                fullWidth
                size="small"
                variant="text"
                color="secondary"
                onClick={() => {
                  navigate("/xpinvestimentos/exterior-historic");
                }}
              >
                Ir para calculadora de DARF
              </Button>
            </Col>
          </Row>
        }
      >
        <Typography.Paragraph style={{ marginTop: "-24px" }}>
          <strong>
            Caso você tenha realizado operações de venda de ativos ou recebido
            cupom de bonds em 2022,
          </strong>{" "}
          certifique-se de que todos os meses estejam com a situação "Regular"
          na Calculadora de DARF.
        </Typography.Paragraph>
      </Modal>

      <ModalIntegrationExterior
        visible={showNoOperationsModal}
        onCancel={() => {
          setShowNoOperationsModal(false);
        }}
      />
    </HomeContainer>
  );
};
